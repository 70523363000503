<template>
  <div>
    <AppNeighborhoodList title="Neighborhood - Entertainment" collection="neighborhood-list" header="Entertainment" :items= neighborhoodEntertainmentList />
    <AppNeighborhoodList title="Neighborhood - Shopping" collection="neighborhood-list" header="Shopping" :items= neighborhoodShoppingList />
    <AppNeighborhoodList title="Neighborhood - Dining" collection="neighborhood-list" header="Dining" :items= neighborhoodDiningList />
    <AppNeighborhoodList title="Neighborhood - Groceries" collection="neighborhood-list" header="Groceries" :items= neighborhoodGroceriesList />
    <AppNeighborhoodList title="Neighborhood - Banks" collection="neighborhood-list" header="Banks" :items= neighborhoodBanksList />
    <AppNeighborhoodList title="Neighborhood - Fitness" collection="neighborhood-list" header="Fitness" :items= neighborhoodFitnessList />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppNeighborhoodList from "@/components/admin/AppNeighborhoodList";

export default {
  name: "Neighborhood",

  components: {
    AppNeighborhoodList
  },

  computed: {
    ...mapGetters([
      "neighborhoodEntertainmentList",
      "neighborhoodShoppingList",
      "neighborhoodDiningList",
      "neighborhoodGroceriesList",
      "neighborhoodBanksList",
      "neighborhoodFitnessList"
    ])
  }
};
</script>
