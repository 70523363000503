<template>
    <v-data-table :headers="headers" :items="items" sort-by="order" :items-per-page="5" class="mb-5">
        <template v-slot:top>
          <v-toolbar flat color="white">
              <v-toolbar-title>{{ title }}</v-toolbar-title>

              <div class="flex-grow-1"></div>

              <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="mt-5" v-on="on">New Item</v-btn>
              </template>
              
              <v-card>
                  <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                  <v-container>
                      <v-row>
                      <v-col cols="12" class="padding-input">
                          <v-text-field v-model="editedItem.title" label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="4" class="padding-input">
                          <v-text-field v-model="editedItem.phone" label="Phone"></v-text-field>
                      </v-col>
                      <v-col cols="8"></v-col>
                      <v-col cols="9" class="padding-input">
                          <v-text-field v-model="editedItem.address.street" label="Street"></v-text-field>
                      </v-col>
                      <v-col cols="3"></v-col>
                      <v-col cols="6" class="padding-input">
                          <v-text-field v-model="editedItem.address.city" label="City"></v-text-field>
                      </v-col>
                      <v-col cols="3" class="padding-input">
                          <v-text-field v-model="editedItem.address.state" label="State"></v-text-field>
                      </v-col>
                      <v-col cols="3" class="padding-input">
                          <v-text-field v-model="editedItem.address.postalCode" label="Postal Code"></v-text-field>
                      </v-col>
                      <v-col cols="3" class="padding-input">
                          <v-text-field v-model="editedItem.address.lat" label="Latitude"></v-text-field>
                      </v-col>
                      <v-col cols="3" class="padding-input">
                          <v-text-field v-model="editedItem.address.lng" label="Longitude"></v-text-field>
                      </v-col>
                      <v-col cols="6"></v-col>
                      <v-col cols="12" class="padding-input">
                          <v-text-field v-model="editedItem.webSite" label="Web Site"></v-text-field>
                      </v-col>
                      <v-col cols="2" class="padding-input">
                          <v-text-field v-model="editedItem.order" label="Order"></v-text-field>
                      </v-col>
                      <v-col cols="10"></v-col>
                      </v-row>
                  </v-container>
                  </v-card-text>

                  <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn color="primary darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="primary darken-1" text @click="save" :disabled="!formIsValid">Save</v-btn>
                  </v-card-actions>
              </v-card>
              </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon class="mr-3" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
  name: "AppNeighborhoodList",

  props: {
    title: {
      type: String,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data: () => ({
    dialog: false,
    editedIndex: -1,
    editedItem: {
      title: "",
      phone: "",
      address: {
        street: "",
        city: "",
        state: "",
        postalCode: "",
        geoPoint: "",
        lat: 0,
        lng: 0
      },
      addressFull: "",
      webSite: "",
      order: 0
    },
    defaultItem: {
      title: "",
      phone: "",
      address: {
        street: "",
        city: "",
        state: "",
        postalCode: "",
        geoPoint: "",
        lat: 0,
        lng: 0
      },
      addressFull: "",
      webSite: "",
      order: 0
    },
    originalItem: {
      title: "",
      phone: "",
      address: {
        street: "",
        city: "",
        state: "",
        postalCode: "",
        geoPoint: "",
        lat: 0,
        lng: 0
      },
      addressFull: "",
      webSite: "",
      order: 0
    },
    formFieldsChanged: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      let list = [
        { text: "Title", value: "title", width: 250 },
        { text: "Phone", value: "phone", width: 130 },
        { text: "Adress", value: "addressFull" },
        { text: "Lat Long", value: "geoPoint" },
        { text: "Web Site", value: "webSite" },
        { text: "Order", value: "order", width: 100 },
        { text: "", value: "action", sortable: false, width: 100 }
      ];

      return list;
    },
    formIsValid() {
      this.updateFormFieldsChanged();
      return this.formFieldsChanged;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.address = Object.assign({}, item.address);
      this.originalItem = Object.assign({}, item);
      this.originalItem.address = Object.assign({}, item.address);

      this.dialog = true;
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch("deleteNeighborhoodList", {
            collection: this.collection,
            item: item
          })
          .then(
            () => {
              const index = this.items.indexOf(item);
              this.items.splice(index, 1);
              this.resetItem();
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
    },
    close() {
      this.dialog = false;
      this.resetItem();
    },
    resetItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.address = Object.assign({}, this.defaultItem.address);
      this.editedIndex = -1;
    },
    save() {
      if (!this.editedItem.title) {
        return;
      }

      if (this.editedIndex > -1) {
        const index = this.editedIndex;

        this.$store
          .dispatch("updateNeighborhoodList", {
            collection: this.collection,
            header: this.header,
            item: this.editedItem
          })
          .then(
            response => {
              Object.assign(this.items[index], response.item);
              Object.assign(this.items[index].address, response.item.address);
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      } else {
        this.$store
          .dispatch("createNeighborhoodList", {
            collection: this.collection,
            header: this.header,
            item: this.editedItem
          })
          .then(
            response => {
              this.items.push(response.item);
            },
            error => {
              // eslint-disable-next-line no-console
              console.error("response: ", error);
            }
          );
      }
      this.close();
    },
    updateFormFieldsChanged() {
      this.formFieldsChanged =
        this.editedItem.title !== this.originalItem.title ||
        this.editedItem.phone !== this.originalItem.phone ||
        this.editedItem.address.street !== this.originalItem.address.street ||
        this.editedItem.address.city !== this.originalItem.address.city ||
        this.editedItem.address.state !== this.originalItem.address.state ||
        this.editedItem.address.postalCode !==
          this.originalItem.address.postalCode ||
        parseFloat(this.editedItem.address.lat) !==
          parseFloat(this.originalItem.address.lat) ||
        parseFloat(this.editedItem.address.lng) !==
          parseFloat(this.originalItem.address.lng) ||
        this.editedItem.webSite !== this.originalItem.webSite ||
        this.editedItem.order !== this.originalItem.order;
    }
  }
};
</script>

<style scoped>
.padding-input {
  padding: 0 10px;
}
</style>